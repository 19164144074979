import React from 'react';
import Layout from '../layout';
import MLink from '../components/MLink';
import './early-access.scss';

const EarlyAccessPage = () => (
  <Layout title="Early access" className="early-access">
    <div className="early-access-title">
      <img className="image" src="/images/MLReef_lock_opt.gif" alt="mlreef early access" />
    </div>
    <div className="early-access-content">
      <h2>MLReef is currently opened for early testers only.</h2>
      <div className="">Do you also want to early test MLReef?</div>
    </div>
    <div className="early-access-actions">
      <MLink
        openApart
        to="https://mlreef.typeform.com/to/oVMRd4"
        className="btn btn-primary"
      >
        Apply for access
      </MLink>
    </div>
  </Layout>
);

export default EarlyAccessPage;
